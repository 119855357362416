@font-face {
  font-family: gotham;
  src: url('./fonts/gotham-medium.otf');
}

@font-face {
  font-family: gothamBook;
  src: url('./fonts/gotham-book.otf');
}

@font-face {
  font-family: gothamBold;
  src: url('./fonts/Gotham-Bold.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYBLACK.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYHEAVYITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYLIGHTITALIC.OTF') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYTHINITALIC.OTF') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/fonts/SFPRODISPLAYSEMIBOLD.ttf');
  font-weight: 600;
  font-style: normal;
}


/* xxxxxxxxxxxx HELVETICA FONTS xxxxxxxxxxxxxxxxxxx */
/* Helvetica Regular */
@font-face {
  font-family: 'Helvetica';
  font-weight: 400;
  src: url('./fonts/Helvetica/Helvetica.ttf');
}

/* Helvetica Bold */
@font-face {
  font-family: 'Helvetica';
  font-weight: 700;
  src: url('./fonts/Helvetica/Helvetica-Bold.ttf');
}

/* Helvetica Bold Oblique */
@font-face {
  font-family: 'Helvetica';
  font-weight: 700;
  font-style: oblique;
  src: url('./fonts/Helvetica/Helvetica-BoldOblique.ttf');
}

/* Helvetica Light */
@font-face {
  font-family: 'Helvetica';
  font-weight: 300;
  src: url('./fonts/Helvetica/helvetica-light-587ebe5a59211.ttf');
}

/* Helvetica Oblique */
@font-face {
  font-family: 'Helvetica';
  font-weight: 400;
  font-style: oblique;
  src: url('./fonts/Helvetica/Helvetica-Oblique.ttf');
}

/* Helvetica Compressed */
@font-face {
  font-family: 'Helvetica Compressed';
  font-weight: 400;
  src: url('./fonts/Helvetica/helvetica-compressed-5871d14b6903a.otf');
}

/* Helvetica Rounded Bold */
@font-face {
  font-family: 'Helvetica Rounded';
  font-weight: 700;
  src: url('./fonts/Helvetica/helvetica-rounded-bold-5871d05ead8de.otf');
}

/* Helvetica Black Condensed */
@font-face {
  font-family: 'Helvetica Black Condensed';
  font-weight: 900;
  src: url('./fonts/Helvetica/Helvetica-Black-Condensed.otf');
}

/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */


body {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  font-family: gotham !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #212529 !important; */
}

body::after {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  content: '';

  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: gotham !important;
}

p {
  font-family: gothamBook !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}