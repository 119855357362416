.container,
.sv_play_container,
.ott_v3_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sv_play_container {
  background-color: #fff;
}

.ott_v3_container {
  background-color: #000;
}

.container::after {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  content: '';
  background-image: linear-gradient(45deg,
      rgba(24, 51, 109, 0),
      rgba(24, 51, 109, 0.28)),
    url('https://d25s2jqw4qdf1e.cloudfront.net/0b0fb7dd-d877-4e84-860f-1d836981849d.png');
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.sv_play_container::after {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  content: '';
  background-color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.ott_v3_container::after {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  content: '';
  background-color: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.container h3 {
  text-align: center;
  font-size: 30px;
  max-width: 350px;
  min-width: 350px;
}

.logo {
  height: 100px;
  margin-top: -20vh;
  margin-bottom: 2vh;
}

.innerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 600px) {

  .container,
  .sv_play_container,
  .ott_v3_container {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    background-image: none;
  }

  .sv_play_container {
    background-color: #fff;
  }

  .ott_v3_container {
    background-color: #000;
  }

  .container::after {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    content: '';
    background-image: linear-gradient(45deg,
        rgba(24, 51, 109, 0),
        rgba(24, 51, 109, 0.28)),
      url('https://d25s2jqw4qdf1e.cloudfront.net/0b0fb7dd-d877-4e84-860f-1d836981849d.png');
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .sv_play_container::after {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    content: '';
    background-color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .ott_v3_container::after {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    content: '';
    background-color: #000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  .container h3 {
    text-align: center;
    margin-top: -5vh;
  }

  .logo {
    position: absolute;
    height: 60px;
    top: 20vh;
    left: 0px;
  }
}